// eslint-disable-next-line complexity
export const shouldUseNewLogin = (): boolean => {
  // Enable on staging, localhost and stationary care tenants
  if (
    window.location.hostname.includes('staging.pflegenavi.at') ||
    window.location.hostname.includes('localhost') ||
    !window.location.hostname.includes('service-provider.pflegenavi.at')
  ) {
    return true;
  }

  const params = new URLSearchParams(window.location.search);
  const useNewLogin =
    params.has('pn-login') || document.cookie.includes('pn-login=true');

  if (useNewLogin) {
    document.cookie = 'pn-login=true';
  }
  return useNewLogin;
};
