import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import type { IKeycloakBasedAuth, LoginResponse } from './type';
import { AuthenticationError, RequiredActions } from './type';
import type { TokenPayload } from '@pflegenavi/shared/utils';
import { getTokenPayloadFromToken } from '@pflegenavi/shared/utils';
import { getFetchOptions } from './utils/getFetchOptions';

function setApplicationHeaders(
  options?: RequestInit & { headers?: Headers }
): RequestInit & { headers?: Headers } {
  // @ts-expect-error //global is untyped
  const application = window.global.pflegenaviApplication;
  // @ts-expect-error //global is untyped
  const applicationVersion = window.global.pflegenaviApplicationVersion;
  // @ts-expect-error //global is untyped
  const tenant = window.global.tenant;
  const headers = options?.headers ?? new Headers();
  headers.set('X-PN-Application', application);
  headers.set('X-PN-Application-Version', applicationVersion);
  if (tenant) {
    headers.set('X-PN-Tenant', tenant);
  }
  const optionsWithApplication = {
    ...options,
    headers,
  };

  return optionsWithApplication;
}

const Context = createContext<IKeycloakBasedAuth | undefined>(undefined);

interface Storage {
  setItem: (key: string, value: string) => Promise<void> | void;
  getItem: (key: string) => Promise<string | null> | string | null;

  removeItem(key: string): void;
}

interface PflegenaviAuthenticationProviderProps {
  storage: Storage;
  getWelcomeToken: () => Promise<string | null>;
  shouldUseNewLogin: boolean;
  apiUrl: string;
  redirectIfNecessary: (url: string) => 'redirected' | undefined;
  /**
   * Allow to register cleanup functions when the user logs out.
   */
  onLogout: () => void;
}

const REFRESH_TOKEN = 'REFRESH_TOKEN';

// @ts-expect-error // TODO: fix this
export const PflegenaviAuthenticationProvider: FC<
  PropsWithChildren<PflegenaviAuthenticationProviderProps>
> = ({
  children,
  storage,
  getWelcomeToken,
  shouldUseNewLogin,
  apiUrl,
  redirectIfNecessary,
  onLogout,
}) => {
  const [, rerender] = useState({});

  const auth = useRef(
    new PflegenaviAuthentication(
      apiUrl,
      () => rerender({}),
      storage,
      redirectIfNecessary,
      onLogout
    )
  );

  // TODO ensure initialization can be multiple times for each token
  useEffect(() => {
    void auth.current.initialize(getWelcomeToken);
  }, [auth, getWelcomeToken]);

  if (!shouldUseNewLogin) {
    return children;
  }

  return (
    <Context.Provider value={auth.current.context()}>
      {children}
    </Context.Provider>
  );
};

export function useAuthentication<T extends boolean>(
  shouldUseNewLogin: T
): T extends true ? IKeycloakBasedAuth : IKeycloakBasedAuth | undefined {
  const auth = useContext(Context);
  if (!auth && shouldUseNewLogin) {
    throw new Error('No authentication provider found');
  }
  // @ts-expect-error // can't type due to dependency on shouldUseNewLogin
  return auth;
}

class PflegenaviAuthentication {
  private logger: undefined | typeof console = undefined;
  private access_token: string | undefined;

  private required_actions: RequiredActions[] = [];
  private required_action_token: string | undefined;

  private error: string | undefined;
  private errorOptions:
    | {
        invitationWasValidFor?: string;
      }
    | undefined;
  private redirectUri: string | undefined;

  private initializing = true;

  private refreshTimer: ReturnType<typeof setTimeout> | undefined = undefined;

  /**
   Promise to avoid running multiple operations at the same time.
   */
  private promise: any;

  constructor(
    private baseUrl: string,
    private rerender: () => void,
    private storage: Storage,
    private redirectIfNecessary: (hostname: string) => 'redirected' | undefined,
    private onLogout: () => void
  ) {}

  private getHeaders(otherHeaders: Headers) {
    const headers =
      setApplicationHeaders({
        headers: otherHeaders,
      }).headers ?? new Headers();

    if (this.access_token) {
      headers.set('Authorization', `Bearer ${this.access_token}`);
    }

    return headers;
  }

  private clearTimer() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = undefined;
    }
  }

  private setError(
    error: string,
    options?: {
      [key: string]: string;
    }
  ) {
    this.logger?.info('Error', error);
    this.access_token = undefined;
    this.required_actions = [];
    this.required_action_token = undefined;
    this.error = error;
    this.errorOptions = options;
    this.redirectUri = undefined;
    this.storage.removeItem(REFRESH_TOKEN);
    this.clearTimer();
  }

  private setRequiredActions(actions: RequiredActions[], token: string) {
    this.access_token = undefined;
    this.required_actions = actions;
    this.required_action_token = token;
    this.error = undefined;
    this.redirectUri = undefined;
    this.storage.removeItem(REFRESH_TOKEN);
    this.clearTimer();
  }

  private setTokens(
    accessToken: string,
    refreshToken: string,
    redirectUri: string | undefined,
    refreshInSeconds: number
  ) {
    this.access_token = accessToken;
    this.required_actions = [];
    this.required_action_token = undefined;
    this.error = undefined;
    this.redirectUri = redirectUri;
    this.storage.setItem(REFRESH_TOKEN, refreshToken);

    this.clearTimer();
    this.refreshTimer = setTimeout(
      this.updateToken.bind(this),
      refreshInSeconds * 1000
    );
  }

  private reset() {
    this.onLogout();
    this.access_token = undefined;
    this.required_actions = [];
    this.required_action_token = undefined;
    this.error = undefined;
    this.redirectUri = undefined;
    this.storage.removeItem(REFRESH_TOKEN);
    this.clearTimer();
  }

  initialize(getWelcomeToken: () => Promise<string | null>): Promise<any> {
    return this.waitWithPromise(
      this.initializeInner.bind(this, getWelcomeToken)
    );
  }

  // eslint-disable-next-line complexity
  async initializeInner(getWelcomeToken: () => Promise<string | null>) {
    this.logger?.info('Initialize');
    const token = await getWelcomeToken();

    this.logger?.info('Weclome token: ', token);
    if (token) {
      const payload = getTokenPayloadFromToken<
        {
          rqac: RequiredActions[];
        } & TokenPayload
      >(token);

      if (!payload || payload.exp * 1000 < new Date().valueOf()) {
        if (payload?.exp && payload?.iat) {
          const invitationWasValidFor =
            (payload.exp - payload.iat) / 60 / 60 / 24; // From seconds to days
          this.setError(AuthenticationError.InvitationExpired, {
            invitationWasValidFor: invitationWasValidFor.toString(),
          });
        }
      } else if (payload.typ === 'refresh_token') {
        this.logger?.info('Refresh token - updating');
        await this.updateTokenInner(token);
      } else {
        this.logger?.info('Valid welcome token', payload.rqac);
        this.setRequiredActions(payload.rqac ?? [], token);

        if (
          payload.typ === 'verify_email' &&
          this.required_actions.includes(RequiredActions.VERIFY_EMAIL)
        ) {
          this.logger?.info('Verifying email');
          await this.verifyEmail();
        } else if (this.required_actions.length === 0) {
          this.logger?.info('No required actions - logging in');
          await this.loginWithToken();
        }
      }
    } else {
      const refreshToken = await this.storage.getItem(REFRESH_TOKEN);
      this.logger?.info('Retrieved refresh token', refreshToken);

      if (refreshToken) {
        this.logger?.info('Refreshing token');
        await this.updateTokenInner(refreshToken);
      }
    }

    this.initializing = false;
    this.logger?.info('Finished initializing');
    this.rerender();
  }

  private loginUrl() {
    return `${this.baseUrl}/api/users/log_in`;
  }

  private logoutUrl() {
    return `${this.baseUrl}/api/users/log_out`;
  }

  private resetPasswordUrl() {
    return `${this.baseUrl}/api/users/reset_password`;
  }

  private verifyEmailUrl() {
    return `${this.baseUrl}/api/users/verify_email`;
  }

  private acceptTermsAndConditionsUrl() {
    return `${this.baseUrl}/api/users/terms_and_conditions`;
  }

  private refreshTokenUrl() {
    return `${this.baseUrl}/api/users/token/refresh`;
  }

  context(): IKeycloakBasedAuth {
    return {
      login: this.login.bind(this),
      logout: this.logout.bind(this),
      loadUserProfile: this.loadUserProfile.bind(this),
      loginWithToken: this.loginWithToken.bind(this),
      updateToken: this.updateToken.bind(this, undefined),
      getTokenImmediate: this.getTokenImmediate.bind(this),
      token: this.access_token,
      resetPassword: this.resetPassword.bind(this),
      resetPasswordConfirm: this.resetPasswordConfirm.bind(this),
      error: this.error,
      errorOptions: this.errorOptions,
      requiredActions: this.required_actions,
      redirectUri: this.redirectUri,
      initializing: this.initializing,
      acceptTermsAndConditions: this.acceptTermsAndConditions.bind(this),
    };
  }

  // eslint-disable-next-line complexity
  async handleLoginResponse(
    result: Response
  ): Promise<LoginResponse | undefined> {
    if (result.status === 200) {
      const json = await result.json();
      const token = json.data.required_action_token;
      if (token) {
        const payload = getTokenPayloadFromToken<
          {
            rqac: RequiredActions[];
          } & TokenPayload
        >(token);
        if (!payload) {
          return {
            status: 'error',
            errorMessage: 'Invalid token',
          };
        }

        this.setRequiredActions(payload.rqac, token);

        return {
          required_actions: payload.rqac as RequiredActions[],
        };
      } else {
        const payload = getTokenPayloadFromToken(json.data.access_token);

        const refreshInSeconds = !payload?.exp
          ? 600
          : (payload.exp * 1000 - new Date().valueOf()) / 1000 - 10;

        if (payload?.reduri) {
          const redirected = this.redirectIfNecessary(
            `${payload.reduri}?key=${json.data.refresh_token}`
          );
          if (redirected) {
            return { status: 'OK' };
          }
        }

        this.setTokens(
          json.data.access_token,
          json.data.refresh_token,
          payload?.reduri,
          refreshInSeconds
        );

        return { status: 'OK' };
      }
    }
    return undefined;
  }

  /**
   * Runs a function if no other promised function is currently running
   */
  async waitWithPromise<T>(callback: () => Promise<T>) {
    if (this.promise) {
      this.promise = this.promise.then(callback);
    } else {
      this.promise = callback();
    }

    const thisPromise = this.promise;
    const result = await thisPromise;
    // this.promise might have changed while we waited for thisPromise
    if (this.promise === thisPromise) {
      this.promise = undefined;
    }
    return result;
  }

  /**
   * Runs a function if no other promised function is currently running
   */
  async discardWithPromise<T>(callback: () => Promise<T>) {
    if (this.promise) {
      await this.promise;
      return undefined;
    }

    this.promise = callback();

    const thisPromise = this.promise;
    const result = await thisPromise;
    if (this.promise === thisPromise) {
      this.promise = undefined;
    }
    return result;
  }

  login(email: string, password: string): Promise<LoginResponse> {
    // Using discard as multiple logins are not allowed -
    // and if we are updating or logging out, we might not need to login again.
    return this.discardWithPromise(this.loginInner.bind(this, email, password));
  }

  async loginInner(email: string, password: string): Promise<LoginResponse> {
    const options = getFetchOptions();
    const fetchResult = await fetch(this.loginUrl(), {
      method: 'POST',
      ...options,
      headers: this.getHeaders(options.headers),
      body: JSON.stringify({
        user: {
          email,
          password,
        },
      }),
    });

    const result = await this.handleLoginResponse(fetchResult);
    this.rerender();
    if (!result || fetchResult.status !== 200) {
      const resultBody = await fetchResult.json().then((json) => json);
      return {
        status: 'error',
        errorMessage: resultBody.errors.detail,
      };
    }

    return result;
  }

  async loginWithToken() {
    const options = getFetchOptions();
    const fetchResult = await fetch(this.loginUrl(), {
      method: 'POST',
      ...options,
      headers: this.getHeaders(options.headers),
      body: JSON.stringify({
        user: {
          token: this.required_action_token,
        },
      }),
    });

    this.logger?.info('Login with token', fetchResult);

    const result = await this.handleLoginResponse(fetchResult);
    if (!result || fetchResult.status === 422) {
      this.setError('Invalid token');
      this.rerender();
      return { status: 'error' };
    }

    this.rerender();
    return result;
  }

  // eslint-disable-next-line class-methods-use-this
  loadUserProfile(): Promise<Keycloak.KeycloakProfile> {
    // @ts-expect-error // TODO: Seems to be unused
    return Promise.resolve(undefined);
  }

  logout(unused: { redirectUri?: string }): Promise<void> {
    // Waiting as we should ensure logouts actually happen
    return this.waitWithPromise(this.logoutInner.bind(this, unused));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async logoutInner(_unused: { redirectUri?: string }): Promise<void> {
    if (!this.access_token && !this.storage.getItem(REFRESH_TOKEN)) {
      // Already logged out. Resetting to be sure.
      this.reset();
      return Promise.resolve(undefined);
    }
    const options = getFetchOptions();
    await fetch(this.logoutUrl(), {
      method: 'DELETE',
      ...options,
      headers: this.getHeaders(options.headers),
    });

    this.reset();

    this.rerender();
    return Promise.resolve(undefined);
  }

  updateToken(refreshToken?: string): Promise<boolean> {
    // Discarding as we should only update once.
    return this.discardWithPromise(
      this.updateTokenInner.bind(this, refreshToken)
    );
  }

  getTokenImmediate(): string | undefined {
    return this.access_token;
  }

  // eslint-disable-next-line complexity
  async updateTokenInner(refreshToken?: string): Promise<boolean> {
    const options = getFetchOptions();
    const refresh_token =
      refreshToken ?? (await this.storage.getItem(REFRESH_TOKEN));

    if (!refresh_token) {
      await this.logoutInner({});
      return false;
    }

    const result = await fetch(this.refreshTokenUrl(), {
      method: 'POST',
      ...options,
      headers: this.getHeaders(options.headers),
      body: JSON.stringify({
        refresh_token,
      }),
    });

    const response = await this.handleLoginResponse(result);
    if (
      (response && 'status' in response && response.status === 'error') ||
      result.status !== 200
    ) {
      await this.logoutInner({});
    }
    this.rerender();
    return Boolean(response);
  }

  async resetPassword(email: string): Promise<Response> {
    const options = getFetchOptions();
    const result = await fetch(this.resetPasswordUrl(), {
      method: 'POST',
      ...options,
      headers: this.getHeaders(options.headers),
      body: JSON.stringify({
        user: {
          email,
        },
      }),
    });

    return result;
  }

  async resetPasswordConfirm(
    token: string | undefined,
    body: {
      password: string;
      passwordConfirmation: string;
    }
  ): Promise<Response> {
    token = this.required_action_token || token;
    if (!token) {
      throw new Error('Missing token to confirm password reset.');
    }
    const options = getFetchOptions();
    const result = await fetch(`${this.resetPasswordUrl()}/${token}`, {
      method: 'PUT',
      ...options,
      headers: this.getHeaders(options.headers),
      body: JSON.stringify({
        user: {
          password: body.password,
          password_confirmation: body.passwordConfirmation,
        },
      }),
    });

    // If the user has required actions, we attempt to login with the token
    if (this.required_action_token) {
      await this.loginWithToken();
    }

    return result;
  }

  async acceptTermsAndConditions(): Promise<Response> {
    const token = this.required_action_token;
    if (!token) {
      throw new Error('Missing token to accept terms and conditions.');
    }
    const options = getFetchOptions();
    const result = await fetch(
      `${this.acceptTermsAndConditionsUrl()}/${token}`,
      {
        method: 'PUT',
        ...options,
        headers: this.getHeaders(options.headers),
      }
    );

    await this.loginWithToken();

    return result;
  }

  async verifyEmail(): Promise<Response> {
    const token = this.required_action_token;
    if (!token) {
      throw new Error('Missing token to verify email.');
    }
    const options = getFetchOptions();
    const result = await fetch(`${this.verifyEmailUrl()}/${token}`, {
      method: 'PUT',
      ...options,
      headers: this.getHeaders(options.headers),
    });

    this.logger?.info('Verify email result', result);

    await this.loginWithToken();

    return result;
  }
}
