import type { KeycloakProfile } from 'keycloak-js';

export enum RequiredActions {
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  TERMS_AND_CONDITIONS = 'terms_and_conditions',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
}

export type LoginResponse =
  | {
      status: 'OK';
    }
  | {
      required_actions: RequiredActions[];
    }
  | {
      status: 'error';
      errorMessage: string;
    };

export interface IKeycloakBasedAuth {
  onTokenExpired?: () => void;
  updateToken: () => Promise<boolean>;
  logout: ({ redirectUri }: { redirectUri?: string }) => Promise<void>;
  token?: string;
  loadUserProfile: () => Promise<KeycloakProfile>;

  /**
   * Additional functions
   */
  login: (email: string, password: string) => Promise<LoginResponse>;
  resetPassword: (email: string) => Promise<Response>;
  resetPasswordConfirm: (
    token: string | undefined,
    body: {
      password: string;
      passwordConfirmation: string;
    }
  ) => Promise<Response>;
  requiredActions: RequiredActions[];
  error?: string;
  errorOptions?: { invitationWasValidFor?: string };
  redirectUri?: string;
  initializing: boolean;
  loginWithToken: () => Promise<
    { required_actions: RequiredActions[] } | { status: string }
  >;
  acceptTermsAndConditions: () => Promise<Response>;
  getTokenImmediate: () => string | undefined;
}

export enum AuthenticationError {
  InvitationExpired = 'invitation_expired',
}
